<template>
  <div class="container-fluid">
    <div class="fixedcenter">
      <div class="clockwrapper">
        <div class="clockinout">
          <button class="btnclock timein active" data-type="timein">
            Time In
          </button>
          <button class="btnclock timeout" data-type="timeout">Time Out</button>
        </div>
      </div>
      <div class="clockwrapper">
        <div class="timeclock">
          <span id="show_day" class="clock-text"></span>
          <span id="show_time" class="clock-time"></span>
          <span id="show_date" class="clock-day"></span>
        </div>
      </div>

      <div class="clockwrapper">
        <div class="userinput">
          <form action="" method="get" accept-charset="utf-8" class="ui form">
            <div
              v-if="this.cc && this.cccc == 'on'"
              class="inline field comment"
            >
              <textarea
                name="comment"
                class="uppercase lightblue"
                rows="1"
                placeholder="Enter comment"
                value=""
              ></textarea>
            </div>
            <div class="inline field">
              <button
                v-if="this.rfid != 'on'"
                id="btnclockin"
                type="button"
                class="ui positive large icon button"
              >
                {{ __("Confirm") }}
              </button>
              <input type="hidden" id="_url" :value="window.location.origin" />
            </div>
          </form>
        </div>
      </div>

      <div class="message-after">
        <p>
          <span id="greetings">Welcome!</span>
          <span id="fullname"></span>
        </p>
        <p id="messagewrap">
          <span id="type"></span>
          <span id="message"></span>
          <span id="time"></span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    cc() {
      return this.$store.state.clockData.cc;
    },
    tz() {
      return this.$store.state.clockData.tz;
    },
    tf() {
      return this.$store.state.clockData.tf;
    },
    rfid() {
      return this.$store.state.clockData.rfid;
    },
  },
  created() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadClockData");
  },
  mounted() {
    console.log(this.$store.state.clockData);
  },
};
</script>
